// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/doce/web-confeitaria/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/home/doce/web-confeitaria/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/home/doce/web-confeitaria/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/home/doce/web-confeitaria/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/doce/web-confeitaria/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/home/doce/web-confeitaria/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1708196709982,
              "slugs": [
                {
                  "depth": 1,
                  "value": "web-confeitaria",
                  "heading": "web-confeitaria"
                }
              ],
              "title": "web-confeitaria"
            },
            "title": "web-confeitaria"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/home/doce/web-confeitaria/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1708196709990,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user/login",
            "layout": false,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/doce/web-confeitaria/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/doce/web-confeitaria/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "dashboard",
        "icon": "dashboard",
        "routes": [
          {
            "path": "/dashboard",
            "redirect": "/dashboard/analysis",
            "exact": true
          },
          {
            "name": "analysis",
            "icon": "smile",
            "path": "/dashboard/analysis",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__analysis' */'/home/doce/web-confeitaria/src/pages/dashboard/analysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "monitor",
            "icon": "smile",
            "path": "/dashboard/monitor",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__monitor' */'/home/doce/web-confeitaria/src/pages/dashboard/monitor'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "workplace",
            "icon": "smile",
            "path": "/dashboard/workplace",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__workplace' */'/home/doce/web-confeitaria/src/pages/dashboard/workplace'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/seguranca",
        "icon": "form",
        "name": "seguranca",
        "routes": [
          {
            "name": "usuario",
            "icon": "smile",
            "path": "/seguranca/usuario",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__usuario' */'/home/doce/web-confeitaria/src/pages/seguranca/usuario'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "perfil",
            "icon": "smile",
            "path": "/seguranca/perfil",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__perfil' */'/home/doce/web-confeitaria/src/pages/seguranca/perfil'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "operacao",
            "icon": "smile",
            "path": "/seguranca/operacao",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__operacao' */'/home/doce/web-confeitaria/src/pages/seguranca/operacao'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "tela",
            "icon": "smile",
            "path": "/seguranca/tela",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__tela' */'/home/doce/web-confeitaria/src/pages/seguranca/tela'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "sistema",
            "icon": "smile",
            "path": "/seguranca/sistema",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__sistema' */'/home/doce/web-confeitaria/src/pages/seguranca/sistema'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "modulo",
            "icon": "smile",
            "path": "/seguranca/modulo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__modulo' */'/home/doce/web-confeitaria/src/pages/seguranca/modulo'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "politicaacesso",
            "icon": "smile",
            "path": "/seguranca/politicaacesso",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__seguranca__politicaacesso' */'/home/doce/web-confeitaria/src/pages/seguranca/politicaacesso'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/cadastro",
        "icon": "form",
        "name": "cadastro",
        "routes": [
          {
            "name": "empresaservico",
            "icon": "smile",
            "path": "/cadastro/empresaservico",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__empresaservico' */'/home/doce/web-confeitaria/src/pages/cadastro/empresaservico'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "cliente",
            "icon": "smile",
            "path": "/cadastro/cliente",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__cliente' */'/home/doce/web-confeitaria/src/pages/cadastro/cliente'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "formapagamento",
            "icon": "smile",
            "path": "/cadastro/formapagamento",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__formapagamento' */'/home/doce/web-confeitaria/src/pages/cadastro/formapagamento'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "marca",
            "icon": "smile",
            "path": "/cadastro/marca",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__marca' */'/home/doce/web-confeitaria/src/pages/cadastro/marca'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "unidademedida",
            "icon": "smile",
            "path": "/cadastro/unidademedida",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__unidademedida' */'/home/doce/web-confeitaria/src/pages/cadastro/unidademedida'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "produto",
            "icon": "smile",
            "path": "/cadastro/produto",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__produto' */'/home/doce/web-confeitaria/src/pages/cadastro/produto'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "precificacao",
            "icon": "smile",
            "path": "/cadastro/precificacao",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__precificacao' */'/home/doce/web-confeitaria/src/pages/cadastro/precificacao'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "precificacao",
            "icon": "smile",
            "path": "/cadastro/gastofixo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cadastro__gastofixo' */'/home/doce/web-confeitaria/src/pages/cadastro/gastofixo'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/pedido",
        "icon": "form",
        "name": "pedido",
        "routes": [
          {
            "name": "pedidocliente",
            "icon": "smile",
            "path": "/pedido/pedidocliente",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pedido__pedidocliente' */'/home/doce/web-confeitaria/src/pages/pedido/pedidocliente'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/orcamentocadastrar",
        "icon": "table",
        "name": "orcamentocadastrar",
        "routes": [
          {
            "path": "/orcamentocadastrar",
            "redirect": "/orcamentocadastrar/fonte",
            "exact": true
          },
          {
            "name": "fonte",
            "icon": "smile",
            "path": "/orcamentocadastrar/fonte",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__fonte' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/fonte'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "funprogramatica",
            "icon": "smile",
            "path": "/orcamentocadastrar/funprogramatica",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__funprogramatica' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/funprogramatica'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "elementodespesa",
            "icon": "smile",
            "path": "/orcamentocadastrar/elementodespesa",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__elementodespesa' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/elementodespesa'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "planointerno",
            "icon": "smile",
            "path": "/orcamentocadastrar/planointerno",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__planointerno' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/planointerno'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "entidade",
            "icon": "smile",
            "path": "/orcamentocadastrar/entidade",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__entidade' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/entidade'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "contacontrole",
            "icon": "smile",
            "path": "/orcamentocadastrar/contacontrole",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__contacontrole' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/contacontrole'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "exercicio",
            "icon": "smile",
            "path": "/orcamentocadastrar/exercicio",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__exercicio' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/exercicio'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "tipoconta",
            "icon": "smile",
            "path": "/orcamentocadastrar/tipoconta",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentocadastrar__tipoconta' */'/home/doce/web-confeitaria/src/pages/orcamentocadastrar/tipoconta'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/orcamentolancar",
        "icon": "table",
        "name": "orcamentolancar",
        "routes": [
          {
            "path": "/orcamentolancar",
            "redirect": "/orcamentolancar/fonte",
            "exact": true
          },
          {
            "name": "cadorcamento",
            "icon": "smile",
            "path": "/orcamentolancar/cadorcamento",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentolancar__cadorcamento' */'/home/doce/web-confeitaria/src/pages/orcamentolancar/cadorcamento'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "cotaempenhar",
            "icon": "smile",
            "path": "/orcamentolancar/cotaempenhar",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentolancar__cotaempenhar' */'/home/doce/web-confeitaria/src/pages/orcamentolancar/cotaempenhar'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "diferido",
            "icon": "smile",
            "path": "/orcamentolancar/diferido",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentolancar__diferido' */'/home/doce/web-confeitaria/src/pages/orcamentolancar/diferido'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "contadotacao",
            "icon": "smile",
            "path": "/orcamentolancar/contadotacao",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentolancar__contadotacao' */'/home/doce/web-confeitaria/src/pages/orcamentolancar/contadotacao'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "lancarcontacontrole",
            "icon": "smile",
            "path": "/orcamentolancar/lancarcontacontrole",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orcamentolancar__lancarcontacontrole' */'/home/doce/web-confeitaria/src/pages/orcamentolancar/lancarcontacontrole'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/reunioes",
        "icon": "table",
        "name": "reunioes",
        "routes": [
          {
            "path": "/reunioes",
            "redirect": "/reunioes/atas",
            "exact": true
          },
          {
            "name": "atas",
            "icon": "smile",
            "path": "/reunioes/atas",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reunioes__atas' */'/home/doce/web-confeitaria/src/pages/reunioes/atas'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard/analysis",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard/analysis",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/doce/web-confeitaria/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
