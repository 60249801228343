import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: 'http://localhost:3333/',
  baseURL: 'https://api.kriptopayment.io/',
  // baseURL: 'http://216.238.99.249:3333/',
  // baseURL: 'http://api.confeitaria:3333/',
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
