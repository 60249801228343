// @ts-nocheck

import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined'

export default {
  DashboardOutlined,
SmileOutlined,
FormOutlined,
TableOutlined
}
    