// @ts-nocheck

  import DashboardOutlined from '@ant-design/icons/es/icons/DashboardOutlined';
import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import FormOutlined from '@ant-design/icons/es/icons/FormOutlined';
import TableOutlined from '@ant-design/icons/es/icons/TableOutlined'
  export default {
    DashboardOutlined,
SmileOutlined,
FormOutlined,
TableOutlined
  }