import { SettingDrawer } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import defaultSettings from '../config/defaultSettings';
import api from './services/api';
const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';
/** 获取用户信息比较慢的时候会展示一个 loading */

export const initialStateConfig = {
  loading: <PageLoading />,
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */

export async function getInitialState() {
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }

    return undefined;
  }; // 如果不是登录页面，执行

  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings,
    };
  }

  return {
    fetchUserInfo,
    settings: defaultSettings,
  };
} // ProLayout 支持的api https://procomponents.ant.design/components/layout

export const layout = ({ initialState, setInitialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      content: initialState?.currentUser?.name,
    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history; // 如果没有登录，重定向到 login

      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    links: isDev
      ? [
          // <Link key="openapi" to="/umi/plugin/openapi" target="_blank">
          //   <LinkOutlined />
          //   <span>OpenAPI</span>
          // </Link>,
          // <Link to="/~docs" key="docs">
          //   <BookOutlined />
          //   <span>业务组件文档</span>
          // </Link>,
        ]
      : [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children, props) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          {children}
          {
            !props.location?.pathname?.includes('/login')
            // && (
            //   <SettingDrawer
            //     disableUrlParams
            //     enableDarkTheme
            //     settings={initialState?.settings}
            //     onSettingChange={(settings) => {
            //       setInitialState((preInitialState) => ({ ...preInitialState, settings }));
            //     }}
            //   />
            // )
          }
        </>
      );
    },
    ...initialState?.settings,
    menu: {
      // locale: true,
      // Re-execute request whenever initialState?.currentUser?.userid is modified
      params: initialState,
      request: async (params, defaultMenuData) => {
        // initialState.currentUser contains all user information

        // //!*PERFIL
        // const perfil = await api.post('/seg_mostrar_polica_perfil', {
        //   // seg_perfils_id: '6405eb4d014aa15208083cff',
        //   seg_perfils_id: params.currentUser.seg_perfils_id,
        //   seg_sistemas_id: '6536bc037889ca43a4d17d0c',
        //   playerId: '',
        //   platform: 'web',
        //   type: 'dashboard',
        // });

        // const routes = [
        //   {
        //     path: '/user',
        //     layout: false,
        //     routes: [
        //       {
        //         path: '/user/login',
        //         layout: false,
        //         name: 'login',
        //         component: './user/Login',
        //       },
        //       {
        //         path: '/user',
        //         redirect: '/user/login',
        //       },
        //       {
        //         name: 'register-result',
        //         icon: 'smile',
        //         path: '/user/register-result',
        //         component: './user/register-result',
        //       },
        //       {
        //         name: 'register',
        //         icon: 'smile',
        //         path: '/user/register',
        //         component: './user/register',
        //       },
        //       {
        //         component: '404',
        //       },
        //     ],
        //   },
        //   {
        //     path: '/dashboard',
        //     name: 'dashboard',
        //     // icon: 'dashboard',
        //     routes: [
        //       {
        //         path: '/dashboard',
        //         redirect: '/dashboard/analysis',
        //       },
        //       {
        //         name: 'analysis',
        //         icon: 'smile',
        //         path: '/dashboard/analysis',
        //         component: './dashboard/analysis',
        //       },
        //     ],
        //   },
        //   // Temporariamente ocuto para utilizar a agenda, Criar módulode perfil
        //   {
        //     path: '/seguranca',
        //     // icon: 'form',
        //     name: 'seguranca',
        //     routes: [
        //       {
        //         name: 'usuario',
        //         icon: 'smile',
        //         path: '/seguranca/usuario',
        //         component: './seguranca/usuario',
        //       },
        //       {
        //         name: 'perfil',
        //         icon: 'smile',
        //         path: '/seguranca/perfil',
        //         component: './seguranca/perfil',
        //       },

        //       {
        //         name: 'operacao',
        //         icon: 'smile',
        //         path: '/seguranca/operacao',
        //         component: './seguranca/operacao',
        //       },
        //       {
        //         name: 'tela',
        //         icon: 'smile',
        //         path: '/seguranca/tela',
        //         component: './seguranca/tela',
        //       },
        //       {
        //         name: 'sistema',
        //         icon: 'smile',
        //         path: '/seguranca/sistema',
        //         component: './seguranca/sistema',
        //       },
        //       {
        //         name: 'modulo',
        //         icon: 'smile',
        //         path: '/seguranca/modulo',
        //         component: './seguranca/modulo',
        //       },
        //       {
        //         name: 'politicaacesso',
        //         icon: 'smile',
        //         path: '/seguranca/politicaacesso',
        //         component: './seguranca/politicaacesso',
        //       },
        //     ],
        //   },
        //   {
        //     path: '/passagemplantao',
        //     // icon: 'form',
        //     name: 'passagemplantao',
        //     routes: [
        //       {
        //         name: 'relpassdeplantao',
        //         icon: 'smile',
        //         path: '/passagemplantao/relpassdeplantao',
        //         component: './passagemplantao/relpassdeplantao',
        //       },
        //     ],
        //   },
        //   {
        //     path: '/',
        //     redirect: '/dashboard/analysis',
        //   },
        //   {
        //     component: '404',
        //   },
        // ];

        // return routes;
        //!*PERFIL
        const perfil = await api.post('/seg_mostrar_polica_perfil', {
          // seg_perfils_id: '6405eb4d014aa15208083cff',
          seg_perfils_id: params.currentUser.seg_perfils_id,
          seg_sistemas_id: '65d0e21f0dd38084cd70d936',
          playerId: '',
          platform: 'web',
          type: 'dashboard',
        });

        console.log('perfil.data----->>', perfil.data);

        // try {
        //   console.log('perfil.data.seg_modulos_id_sub._id----->>', perfil.data.seg_modulos_id_sub);
        // } catch (error) {
        //   console.log('error', error);
        // }

        // console.log('params', params);
        // console.log('params.currentUser.seg_perfils_id', params.currentUser.seg_perfils_id);
        let menu_home;
        if (perfil.data.length > 0) {
          // alert('O usuário não tem perfil', 'O usuário não tem perfil.');
          // return;

          // Agrupar para não ficar repetindo o nome e cod do leito
          menu_home = Object.values(
            perfil.data.reduce((acc, item) => {
              if (!acc[item.seg_modulos_id_sub._id])
                acc[item.seg_modulos_id_sub._id] = {
                  _id: item.seg_modulos_id_sub._id,
                  mod: item.seg_modulos_id_sub.mod_param1,
                };
              return acc;
            }, {}),
          );

          // try {
          //   console.log('menu_home', menu_home);
          //   console.log('menu_home.length', menu_home.length);
          // } catch (error) {
          //   console.log('error1', error);
          // }
        }

        console.log('menu_home', menu_home);

        let data_menu = [];
        let data_mod = [];
        let data_mod_tel = [];

        for (let index = 0; index < menu_home.length; index++) {
          const element = menu_home[index];
          //console.log('element', element);
          // console.log('element._id', element._id);

          for (let index_perfil = 0; index_perfil < perfil.data.length; index_perfil++) {
            const element_perfil = perfil.data[index_perfil];

            if (element._id == element_perfil.seg_modulos_id_sub._id) {
              // console.log('1-element._id ', element._id);
              // console.log(
              //   '2-element_perfil.seg_modulos_id_sub._id ',
              //   element_perfil.seg_modulos_id_sub._id,
              // );
              // console.log('3-element_perfil ', element_perfil);
              // console.log(
              //   '4-element_perfil.seg_modulos_id_sub ',
              //   element_perfil.seg_modulos_id_sub,
              // );
              // console.log(
              //   '5-element_perfil.seg_modulos_id_sub.mod_param1 ',
              //   element_perfil.seg_modulos_id_sub.mod_param1,
              // );

              // data.push({
              //   name: 'usuario',
              //   icon: 'smile',
              //   path: '/seguranca/usuario',
              //   component: './seguranca/usuario',
              // });

              data_mod_tel.push({
                name: element_perfil.seg_telas_id_sub.tel_param1,
                icon: 'smile',
                path:
                  '/' +
                  element_perfil.seg_modulos_id_sub.mod_param1 +
                  '/' +
                  element_perfil.seg_telas_id_sub.tel_param1,
                component:
                  './' +
                  element_perfil.seg_modulos_id_sub.mod_param1 +
                  '/' +
                  element_perfil.seg_telas_id_sub.tel_param1,
              });

              // console.log('6-data_mod_tel ', data_mod_tel);
            }

            // console.log('7-index_perfil ', index_perfil);
            // console.log('7-perfil.data.length ', perfil.data.length - 1);

            if (index_perfil === perfil.data.length - 1) {
              data_mod.push({
                path: '/' + element.mod,
                // icon: 'form',
                name: element.mod,
                routes: data_mod_tel,
              });

              console.log('----data_mod--->', data_mod);
              console.log('----data_menu--->', data_menu);

              data_mod_tel = [];
            }
          }
        }

        console.log('data_mod', data_mod);
        
        return data_mod;
      },
    },
  };
};
